/* eslint-disable @typescript-eslint/default-param-last */
type Token = {
  token: string;
  refresh_token: string;
};

export type LoginAction = {
  type: 'login/token';
  payload: Token;
};

const initValue: Token = {
  token: '',
  refresh_token: '',
};

export default function reducer(state = initValue, action: LoginAction) {
  const { type, payload } = action;
  if (type === 'login/token') {
    return payload;
  }

  return state;
}
