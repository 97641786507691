/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable prefer-destructuring */

import qs from 'qs';

const { userAgent } = navigator;
export const isAndroid =
  userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1; // Android终端
export const isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // iOS终端

/**
 * [isWeixin 判断是否微信浏览器]
 */
export const isWeixin = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  return !!ua.match(/MicroMessenger/i);
};

/**
 * 获取手机系统名称
 * @returns {string|undefined}
 */
export const getPlatform = () =>
  isiOS ? 'iOS' : isAndroid ? 'Android' : undefined;

/**
 * 获取url参数
 * @returns {any}
 */
export const getUrlParams = () => {
  let search = '';
  if (window.location.search) {
    search = window.location.search.substring(1);
  } else if (window.location.hash) {
    search = window.location.hash.split('?')[1];
  }

  return qs.parse(search);
};

/**
 * px2vw
 * @param px {number}
 * @returns {string}
 */

export const px2vw = (px: number) => `${(px / 375) * 100}vw`;

/**
 * 延迟
 * @param time
 * @returns {Promise<unknown>}
 */
export const sleep = (time: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, time);
  });

/**
 * 将图片转为base64格式
 * @param url {string}
 * @param crossOrigin {string}
 * @returns {Promise<unknown>}
 */
export const imgToBase64 = (url: string, crossOrigin: string) =>
  new Promise((resolve) => {
    const img = new Image();

    img.onload = () => {
      const c = document.createElement('canvas');

      c.width = img.naturalWidth;
      c.height = img.naturalHeight;

      const cxt = c.getContext('2d');

      cxt?.drawImage(img, 0, 0);

      // 得到图片的base64编码数据
      resolve(c.toDataURL('image/png'));
    };

    // 结合合适的CORS响应头，实现在画布中使用跨域<img>元素的图像
    crossOrigin && img.setAttribute('crossOrigin', crossOrigin);
    img.src = url;
  });

/**
 * 生成随机数
 * @param number 位数
 * @returns {string}
 */
export const randomString = (number = 8) => {
  const t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
  const l = t.length;
  let string = '';
  for (let i = 0; i < number; i++) {
    string += t.charAt(Math.floor(Math.random() * l));
  }
  return string;
};

export default {
  isAndroid,
  isiOS,
  isWeixin,
  getPlatform,
  getUrlParams,
  px2vw,
  sleep,
  imgToBase64,
  randomString,
};
