/* eslint-disable import-helpers/order-imports */
import { StrictMode } from 'react';
import { render } from 'react-dom';

import 'antd-mobile/es/global';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets
import './styles/index.scss';
import './styles/antd-var.scss';
// import './utils/noScale';

import { Provider } from 'react-redux';
import { Toast } from 'antd-mobile';

import App from './App';
import reportWebVitals from './reportWebVitals';

import '@/i18n';
import store from '@/store';

Toast.config({
  duration: 3000,
  position: 'bottom',
});

render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
