/* eslint-disable import-helpers/order-imports */
import React, { Suspense } from 'react';
import { Route, Router, Switch } from 'react-router-dom';

import './App.scss';
import { DotLoading } from 'antd-mobile';
import Utils from '@/utils';

const NotFound = React.lazy(() => import('@/pages/NotFound'));
const Fault = React.lazy(() => import('@/pages/Fault'));
const OAuth = React.lazy(() => import('@/pages/OAuth'));
const UseHelp = React.lazy(() => import('@/pages/UseHelp'));
const Download = React.lazy(() => import('@/pages/Download'));
const VideoDetail = React.lazy(() => import('@/pages/VideoDetail'));

export default function App() {
  return (
    <Router history={Utils.History}>
      <div className="app">
        <Suspense fallback={<DotLoading color="primary" />}>
          <Switch>
            <Route component={Fault} path="/fault" />
            <Route component={OAuth} path="/oauth/login" />
            <Route component={Download} path="/download" />
            <Route component={UseHelp} path="/useHelp" />
            <Route component={VideoDetail} path="/videoDetail" />
            <Route component={NotFound} path="*" />
          </Switch>
        </Suspense>
      </div>
    </Router>
  );
}
