/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
import { initReactI18next } from 'react-i18next';

import Utils from '@/utils';
import i18n from 'i18next';

const {
  Common: { getUrlParams },
} = Utils;

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        ...require('../locales/en.json'),
        u200: { ...require('../locales/u200/en.json') },
        u260: { ...require('../locales/u260/en.json') },
        u200Lite: { ...require('../locales/u260/en.json') },
        u300: { ...require('../locales/u300/en.json') },
      },
    },
    zh: {
      translation: {
        ...require('../locales/zh.json'),
        u200: { ...require('../locales/u200/zh.json') },
        u260: { ...require('../locales/u260/zh.json') },
        u200Lite: { ...require('../locales/u260/zh.json') },
        u300: { ...require('../locales/u300/zh.json') },
      },
    },
    ko: {
      translation: {
        ...require('../locales/ko.json'),
        u200: { ...require('../locales/u200/ko.json') },
        u260: { ...require('../locales/u260/ko.json') },
        u200Lite: { ...require('../locales/u260/ko.json') },
        u300: { ...require('../locales/u300/ko.json') },
      },
    },
    ru: {
      translation: {
        ...require('../locales/ru.json'),
        u200: { ...require('../locales/u200/ru.json') },
      },
    },
  },
  lng: (getUrlParams()?.lang || 'zh').substring(0, 2), // 设置语言
  fallbackLng: 'zh', // 默认语言设置，当其他语言没有的情况下，使用 fallbackLng 作为默认语言

  interpolation: {
    escapeValue: false, // not needed for react!!
  },
});

export default i18n;
